/**axios封装
 * 请求拦截、相应拦截、错误统一处理
 */
//  import Vue from "vue";
import axios from 'axios'
import { Message } from 'element-ui'
import router from '../router'
import { clearStorage } from '@/assets/js/utils'

// 环境的切换
// if (process.env.NODE_ENV == "development") {
//   axios.defaults.baseURL = "/api";
// } else if (process.env.NODE_ENV == "debug") {
//   axios.defaults.baseURL = "";
// } else if (process.env.NODE_ENV == 'production') {
//     axios.defaults.baseURL = 'http://api.123dailu.com/';
// }
axios.defaults.baseURL = '/api'

// 请求超时时间
axios.defaults.timeout = 60000

// post请求头
axios.defaults.headers.post['Content-Type'] = 'application/json'

// 请求拦截器
axios.interceptors.request.use(
  (config) => {
    try {
      const data = JSON.parse(config.data)
      const compactData = Object.keys(data).reduce((acc, key) => {
        if (data[key] !== '' && data[key] !== undefined && data[key] !== null) {
          acc[key] = data[key]
        }
        return acc
      }, {})
      config.data = compactData
    } catch (error) {
      //   console.log(error);
    }
    return config
  },
  (error) => {
    return Promise.error(error)
  }
)

// 响应拦截器
axios.interceptors.response.use(
  (response) => {
    // if (response.data.errorCode == 'CERES_ERROR_102') {
    //     axios.get('/user/refreshToken', {}).then((res) => {
    //         console.log('刷新：', res);
    //     })
    // }
    if (
      response.data.errorCode == 'CERES_ERROR_101' ||
      response.data.errorCode == 'CERES_ERROR_103' ||
      response.data.errorCode == 'CERES_ERROR_107'
    ) {
      setTimeout(() => {
        clearStorage()
        router.push({ path: '/' })
      }, 500)
    }
    if (response.status === 200) {
      return Promise.resolve(response)
    } else {
      return Promise.reject(response)
    }
  },
  // 服务器状态码不是200的情况
  (error) => {
    if (error.response.status) {
      switch (error.response.status) {
        // 401: 未登录
        // 未登录则跳转登录页面，并携带当前页面的路径
        // 在登录成功后返回当前页面，这一步需要在登录页操作。
        case 401:
          Message({
            message: 'token失效，请重新登录',
            duration: 2000,
            center: true,
          })
          clearStorage()
          router.push({ path: '/' })
          break
        // 403 token过期
        // 登录过期对用户进行提示
        // 清除本地token和清空vuex中token对象
        // 跳转登录页面
        case 403:
          Message({
            message: '登录过期，请重新登录',
            duration: 2000,
            center: true,
          })
          clearStorage()
          setTimeout(() => {
            router.push({ path: '/' })
          }, 1000)
          break
        // 404请求不存在
        case 404:
          Message({
            message: '网络请求不存在',
            duration: 2000,
            center: true,
          })
          break
        // 其他错误，直接抛出错误提示
        default:
          Message({
            message: '网络错误，请联系管理员',
            duration: 2000,
            center: true,
          })
      }
      return Promise.reject(error.response)
    }
  }
)
/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params,
      })
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err.data)
      })
  })
}
/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, JSON.stringify(params))
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err.data)
      })
  })
}
/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function uploadForm(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, params, {
        'Content-Type': 'multipart/form-data',
      })
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err.data)
      })
  })
}
