import CryptoJS from 'crypto-js'
import cookies from 'vue-cookies'

export const decrypt = (word, keyStr) => {
  // aes解密
  word = word.replace(/"/g, '')
  let encryptedHexStr = CryptoJS.enc.Base64.parse(word)
  let encryptedBase64Str = CryptoJS.enc.Base64.stringify(encryptedHexStr)
  keyStr = keyStr ? keyStr : 'Ui1yCabO99376Yu7'
  let key = CryptoJS.enc.Utf8.parse(keyStr)
  let decryptedData = CryptoJS.AES.decrypt(encryptedBase64Str, key, {
    // iv,
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  })
  return decryptedData.toString(CryptoJS.enc.Utf8)
}

export const clearStorage = () => {
  localStorage.removeItem('permissionsList')
  localStorage.removeItem('userPermissionsList')
  cookies.remove('ceres_manage_token')
}

//取任意天数日期
export function getDayBefore(day = 0, month = 0, year = 0) {
  let date = new Date()
  let timestamp = date.getTime()
  let newDate = new Date(timestamp - day * 24 * 3600 * 1000)
  let Y
  let M
  let D
  if (!(date instanceof Date)) {
    date = new Date(String(date).replace(/-/g, '/'))
  }
  timestamp = date.getTime()
  newDate = new Date(timestamp - day * 24 * 3600 * 1000)
  M = newDate.getMonth() + 1
  D = newDate.getDate()
  Y = newDate.getFullYear()
  M = M - month
  while (M <= 0) {
    M += 12
    Y--
  }
  while (M > 12) {
    M -= 12
    Y++
  }
  Y = Y - year
  if (M < 10) M = '0' + M
  if (D < 10) D = '0' + D
  return [Y, M, D].join('-')
}

//处理小数的加减乘除
export function floorKeep(num1, num2, type, digits) {
  var a = num1 + ''
  var b = num2 + ''
  var c1 = parseFloat(a) + ''
  var c2 = parseFloat(b) + ''
  var c = (c1.length > 0 || c2.length > 0) && c2.length > c1.length ? c2 : c1
  c = Math.pow(10, c.length + 1)
  var r = 0
  digits = digits == 0 ? 0 : digits ? digits * 1 : 3
  if (type == 1) {
    //减
    r = (Math.round(num1 * c) - Math.round(num2 * c)) / c
  } else if (type == 2) {
    //加
    r = (Math.round(num1 * c) + Math.round(num2 * c)) / c
  } else if (type == 3) {
    //乘
    r = (Math.round(num1 * c) * Math.round(num2 * c)) / c / c
  } else if (type == 4) {
    //除
    r = Math.round(num1 * c) / Math.round(num2 * c)
  }
  if ((r + '').indexOf('.') > -1 && (r + '').split('.')[1].length > digits) {
    r = r.toFixed(digits)
  }
  return r
}
