/**
 * api接口统一管理
 */
import { get, post, uploadForm } from './http'

// 密码登录
export const loginPwd = (p) => get('/user/pwd/signIn', p)
export const passwordUpdate = (p) => post('/user/pwdModify', p)
// 验证码登录
export const loginCode = (p) => post('/user/code/signIn', p)
// 退出登录
export const loginOut = (p) => get('/user/code/signUp', p)
// 短信发送
export const sendCode = (p) => get('/sms/sendCode', p)
// 获取权限的api
export const getApi = (p) => get('/right/role/queryRightApi', p)
// 刷新token
export const refreshToken = (p) => get('/user/refreshToken', p)
// 用户信息查询
export const getUserInfo = (p) => get('/user/queryInfo', p)
// 用户菜单列表
export const getUserMenu = (p) => get('/user/userMenu', p)
// 权限菜单列表
export const getMenu = (p) => get('/right/queryRightList', p)
// 查询角色列表
export const getRoleList = (p) => get('/right/role/list', p)
// 查询角色权限列表
export const getRolePermissionsList = (p) => get('/right/role/rightList', p)
// 用户权限列表
export const getUserPerList = (p) => get('/user/userRtList', p)
// 添加/修改角色
export const modifyRole = (p) => post('/right/role/modifyRole', p)
// 删除角色
export const roleDel = (p) => get('/right/role/delRole', p)
// 注册
export const userRegistered = (p) => post('/user/registered', p)
// 删除用户
export const userDel = (p) => get('/user/delUser', p)
// 账号停用/启用
export const userStop = (p) => get('/user/deactivate', p)
// 新增修改员工
export const modifyUserList = (p) => post('/user/modifyUser', p)
// 员工管理列表
export const getUserList = (p) => get('/user/queryUserInfo', p)

// 新增支持银行
export const contactLineAdd = (p) => post('/contactLine/add', p)
export const contactLineQuery = (p) => post('/contactLine/query', p)
export const contactLineUpdate = (p) => post('/contactLine/update', p)
export const contactLineEnableOrDisable = (p) =>
  post('/contactLine/enableOrDisable', p)

// 平台管理list
export const platManageList = (p) => post('/isv/query', p)
export const institutionList = (p) => post('/institution/query', p)
// 产业园list
export const queryIndustryList = (p) => post('/industry/list', p)
// 渠道是否可操作
export const channelApp = (p) => post('/isv/queryChannelApp', p)
// 添加平台
export const addPlat = (p) => post('/isv/add', p)
// 编辑平台信息
export const editPlat = (p) => post('/isv/edit', p)
// 网商渠道
export const internetChannel = (p) => uploadForm('/isv/createAntbIsvChannel', p)
export const syncAntbV2ConfigFromAntb = (p) =>
  uploadForm('/antb/syncAntbV2ConfigFromAntb', p)
export const internetV2Channel = (p) =>
  uploadForm('/isv/createAntbV2IsvChannel', p)
// 邮储渠道
export const postalStorageChannel = (p) => post('/isv/createPsbcIsvChannel', p)
export const postalStorageChannelV2 = (p) =>
  post('/isv/createPsbcV2IsvChannel', p)
export const postSpabStarChannel = (p) =>
  post('/isv/createSpabStarIsvChannel', p)
export const postSpabStarUpdateChannel = (p) =>
  post('/isv/editChannelConfig', p)
export const cebStorageChannel = (p) => post('/isv/createCebIsvChannel', p)
// 工行渠道
export const icbcChannel = (p) => uploadForm('/isv/createIcbcIsvChannel', p)
// 工行渠道聚富通
export const icbcv2Channel = (p) => uploadForm('/isv/createIcbcV2IsvChannel', p)
export const icbcv3Channel = (p) => uploadForm('/isv/createIcbcV3IsvChannel', p)
export const spabankChannel = (p) => uploadForm('/isv/createSpabIsvChannel', p)
export const spabankEditChannel = (p) => uploadForm('/isv/editChannelConfig', p)

export const spabankLicenseChannel = (p) => uploadForm('/isv/createSpabLicenseIsvChannel', p)
export const spabankLicenseEditChannel = (p) => uploadForm('/isv/editChannelConfig', p)
// 开户管理列表
export const openManageList = (p) => post('/account/list', p)
// 开户详情
export const openAccountDetail = (p) => post('/account/detail', p)
export const accountDetailQuery = (p) =>
  post('/account/detail/queryForUpdate', p)
export const accountDetailUpdate = (p) => post('/account/detail/update', p)
export const accountAgentList = (p) => post('/account/agent/list', p)
export const accountAgentUnbind = (p) => post('/account/agent/unbind', p)
// 账户管理列表
export const accountManageList = (p) => post('/account/balance/list', p)
export const accountManageUnifiedList = (p) =>
  post('/account/balance/unified/list', p)
// 账户详情
export const accountDetail = (p) => post('/account/balance/detail', p)
export const accountUnifiedDetail = (p) =>
  post('/account/balance/unified/detail', p)

export const getTradeMessageApi = (p) => post('/trade/getTradeMessage', p)
export const getExpiredMemberApplyApi = (p) =>
  get('/trade/expiredMemberApply', p)
export const getTradeReceiptApi = (p) => get('/trade/getTradeReceipt', p)
// 交易列表
export const tradeRecordList = (p) => post('/trade/queryTradeRecord', p)
export const tradeRepaymentList = (p) => post('/trade/queryTradeRepayment', p)
export const rechargeRefundList = (p) => post('/trade/queryRechargeRefund', p)
// 充值列表
export const rechargeList = (p) => post('/trade/queryTradeRecharge', p)
// 提现列表
export const withdrawList = (p) => post('/trade/queryTradeWithdraw', p)
// 资金归集列表
export const cashsweepList = (p) => post('/trade/queryCashSweep', p)
// 渠道列表
export const channelListApi = (p) => post('/isv/isvChannelList', p)
// 渠道停用启用
export const channelOnOff = (p) => post('/isv/changeIsvChannelState', p)
// 主渠道设置
export const channelMainSet = (p) => post('/isv/switchMainChannel', p)
// 渠道设置开关设置  新接口
export const channelSwitchApi = (p) => post('/isv/channelSwitch', p)
export const queryChannelConfigApi = (p) => post('/isv/queryChannelConfig', p)
// 平台启用或停用
export const platChangeOnOff = (p) => post('/isv/changeState', p)
// 操作日志
export const opLogList = (p) => post('/operate_log/query', p)
// 操作模块
export const opModuleList = (p) => post('/operate_log/listOperateItem', p)
// 用户列表信息查询
export const userInfoList = (p) => get('/user/queryUserInfo', p)
export const listStatementDetail = (p) => post('/trade/listStatementDetail', p)
export const listReceiptApi = (p) => post('/receipt/listReceipt', p)
export const applyReceiptApi = (p) => post('/receipt/applyReceipt', p)
export const exportIsvConfig = (p) => get('/isv/exportIsvConfig', p)
export const isvSettingQueryApi = (p) => post('/isv/isvSettingQuery', p)
export const isvSettingApi = (p) => post('/isv/isvSetting', p)
//手动入账
export const processInTransitRechargeApi = (p) =>
  post('/trade/processInTransitRecharge', p)
